<template>
  <div>
    <NewTicket />
  </div>
</template>

<script>
import NewTicket from "../../components/Support/NewTicket";
export default {
  components: {
    NewTicket
  }
};
</script>
