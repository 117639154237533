<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <h4 class="text-primary">{{ submit }}</h4>
    </div>
    <div class="border-top mb-4"></div>
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-5">
          <div class="card-body">
            <form method="post" @submit.prevent="newTicket">
              <div class="d-flex flex-column text-left">
                <div class="col-md-12 form-group mb-3">
                  <label for="subject">Subject:</label>
                  <input
                    v-model="ticket.subject"
                    type="text"
                    id="subject"
                    class="form-control"
                    placeholder="Subject"
                    required
                  />
                </div>
                <div class="col-md-12 form-group mb-3">
                  <label for="department">Department:</label>
                  <select
                    class="form-control"
                    id="department"
                    v-model="ticket.department"
                    required
                  >
                    <option value="">Department</option>
                    <option>Kiosk</option>
                    <option>Router</option>
                    <option>Other</option>
                  </select>
                </div>
                <div class="col-md-12 form-group mb-3">
                  <label for="priority">Priority:</label>
                  <select
                    class="form-control"
                    id="priority"
                    v-model="ticket.priority"
                    required
                  >
                    <option value="">Priority</option>
                    <option value="Low">Low</option>
                    <option value="Medium">Medium</option>
                    <option value="High">High</option>
                  </select>
                </div>
                <div class="col-md-12 form-group mb-3">
                  <label for="message">Message:</label>
                  <textarea
                    v-model="ticket.message"
                    class="form-control"
                    placeholder="message"
                    id="message"
                    rows="10"
                    required
                  />
                </div>
              </div>
              <button
                class="btn btn-success w-50 font-weight-bold text-uppercase my-3"
                type="submit"
              >
                {{ submit }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "NewTicket",
  data() {
    return {
      ticket: {
        subject: "",
        department: "",
        priority: "",
        message: ""
      },
      submit: "Create New Ticket"
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"])
  },
  methods: {
    async newTicket() {
      this.ticket.user = this.loggedInUser.id;
      try {
        await axios.post("v1/tickets/", this.ticket).then(response => {
          if (response.status === 201) {
            this.$swal(
              "Success",
              "Ticket submitted, we'll get back to you as soon as possible.",
              "success"
            );
            this.$router.push({
              name: "support-details",
              params: { id: response.data.id }
            });
          }
        });
      } catch (error) {
        if (error.response.data.detail) {
          this.$swal("Error", `${error.response.data.detail}`, "error");
        } else {
          this.$swal("Error", `Could not create ticket at this time`, "error");
        }
      }
    }
  }
};
</script>
